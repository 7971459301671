<template>
  <input
    :value="value"
    onClick="this.select();"
    @input="$emit('input', $event.target.value)"
    @keyup="$emit('keyup')"
    @blur="$emit('blur')"
    @keyup.enter.prevent="$emit('enter')"
    @change="$emit('change', $event)"
  />
</template>

<script>
export default {
  props: ['value']
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
input {
  border: 0;
  background-color: transparent;
  color: $blue;
  font-weight: 700;
  outline: 0;
  width: 100%;

  &:not(:disabled) {
    @include hover();
  }
  &:not(:focus) {
    text-decoration: underline;
  }

  &::placeholder {
    color: $blue;
    font-weight: 500;
  }
  &:-moz-placeholder {
    color: $blue;
    font-weight: 500;
  }
  &::-moz-placeholder {
    color: $blue;
    font-weight: 500;
  }
  &:-ms-input-placeholder {
    color: $blue;
    font-weight: 500;
  }
  &::-webkit-input-placeholder {
    color: $blue;
    font-weight: 500;
  }
}
</style>