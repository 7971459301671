<template>
  <div class="add-dentist" @click.self="$emit('close')">
    <div class="add-dentist-modal">
      <input
        v-model.trim="search"
        ref="selectUserInput"
        id="selectUserInput"
        type="text"
        placeholder="Потърсете името на вашия дентален лекар"
        @focus="getUsers()"
        @keydown="handleKeyup($event)"
      />
      <div class="dentist-list" v-if="users !== null">
        <p
          :class="{ 'active': active === i }"
          v-for="(u, i) in users"
          :key="u._id"
          @mousedown="$emit('selected', u)"
        >{{ u.name }}</p>
        <p class="no-users" v-if="!users.length">Няма резултати</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selected', 'iniciator'],
  data() {
    return {
      users: null,
      active: 0,
      search: ''
    };
  },
  watch: {
    search: 'getUsers'
  },
  methods: {
    handleKeyup(e) {
      const keys = [
        'Tab',
        'Backspace',
        'ArrowDown',
        'ArrowUp',
        'Enter',
        'Escape'
      ];
      if (keys.indexOf(e.key) > -1) e.preventDefault();

      if (e.key === 'Tab') {
        if (this.users.length) {
          this.$emit('selected', this.users[this.active]);
          this.search = '';
          this.getUsers();
        }
      } else if (e.key === 'Backspace') {
        if (!this.search) {
          this.$emit('backspace');
        } else {
          this.search = this.search.slice(0, this.search.length - 1);
        }
        this.getUsers();
      } else if (e.key === 'ArrowDown') {
        if (this.active < this.users.length - 1) {
          this.active += 1;
        }
      } else if (e.key === 'ArrowUp') {
        if (this.active > 0) {
          this.active -= 1;
        }
      } else if (e.key === 'Enter') {
        if (this.users.length) {
          this.$emit('selected', this.users[this.active]);
          if (this.advanced) {
            this.$refs.selectUserInput.blur();
            this.search = '';
          } else {
            this.getUsers();
          }
        }
      } else if (e.key === 'Escape') {
        this.$emit('close');
      }
    },
    getUsers() {
      this.active = 0;

      if (this.iniciator.role === 'dentist') {
        if (this.selected.map(ut => ut._id).indexOf(this.iniciator._id) > -1)
          this.users = [];
        else {
          this.users = [this.iniciator];
        }
      } else {
        let url = '/users/dentists';
        if (this.search) {
          url += `?search=${this.search}`;
        }
        if (this.selected) {
          for (let i in this.selected) {
            const prefix =
              i !== '0' ? ',' : `${this.search ? '&' : '?'}ignore=`;
            url += prefix + this.selected[i]._id;
          }
        }
        this.$apiService.get(url).then(res => {
          this.users = res.data;
        });
      }
    }
  },
  mounted() {
    this.$refs.selectUserInput.focus();
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.add-dentist {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  .add-dentist-modal {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 340px;
    input {
      height: $input-height;
      width: 100%;
      padding: 0 10px;
      &:focus {
        outline: 1px solid $blue;
      }
    }
    .dentist-list {
      color: #000;
      cursor: pointer;
      margin-top: 10px;
      p {
        padding: 10px 5px;
        @include transition(all 0.2s $cubic);
        &.active {
          background-color: rgba($color: $blue, $alpha: 0.3);
        }
        &:hover {
          background-color: rgba($color: $blue, $alpha: 0.15);
        }
      }
    }
  }
}
</style>