<template>
  <Panel :close="true" @close-panel="close()" :tabsSpace="tabsSpace">
    <!-- LOGIN -->
    <div class="panel__content">
      <div class="header">
        <div class="icon">g</div>
        <div>
          <p class="strong">Потребителски профил</p>
          <p>Преглед и редакция на основната информация в профила</p>
        </div>
      </div>
      <form>
        <table>
          <tr v-if="currentUser.role === 'admin' || currentUser.role === 'employee'">
            <td>
              <span class="icon">Y</span>
              <label>РОЛЯ</label>
            </td>
            <td>
              <Role
                :class="{ 'role--hover': user._id !== currentUser._id  }"
                :role="user.role"
                @click="nextRole"
              />
            </td>
          </tr>
          <tr v-if="shouldSee">
            <td>
              <span class="icon">c</span>
              <label for="userPanelUsername">*ПОТРЕБИТЕЛСКО ИМЕ</label>
            </td>
            <td>
              <ClickableInput
                v-model="user.username"
                id="userPanelUsername"
                placeholder="Добави"
                @keyup="formatUsername()"
                @enter="save()"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="icon">R</span>
              <label
                for="userPanelName"
              >{{ user.role === 'pacient' || user.role === 'dentist' ? '*' : null}}ИМЕНА</label>
            </td>
            <td>
              <ClickableInput
                v-model="user.name"
                id="userPanelName"
                placeholder="Добави"
                @keyup="formatName()"
                @enter="save()"
              />
            </td>
          </tr>
          <tr v-if="shouldSee">
            <td>
              <span class="icon">N</span>
              <label for="userPanelEmail">{{ user.role === 'dentist' ? '*' : null}}Е-MAIL</label>
            </td>
            <td>
              <ClickableInput
                v-model="user.email"
                type="email"
                id="userPanelEmail"
                placeholder="Добави"
                @enter="save()"
              />
            </td>
          </tr>
          <tr v-if="shouldSee">
            <td>
              <span class="icon">U</span>
              <label for="userPanelPassword">{{ !id ? '*' : null}}ПАРОЛА</label>
            </td>
            <td>
              <ClickableInput
                v-model="user.password"
                :type="user.role === 'pacient' ? 'text' : 'password'"
                id="userPanelPassword"
                placeholder="Добави"
                @enter="save()"
              />
            </td>
          </tr>
          <tr v-if="shouldSee">
            <td>
              <span class="icon">M</span>
              <label for="userPanelLocation">{{ user.role === 'dentist' ? '*' : null}}АДРЕС</label>
            </td>
            <td>
              <ClickableInput
                v-model="user.location"
                id="userPanelLocation"
                placeholder="Добавете адрес"
                @enter="save()"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="icon">X</span>
              <label for="userPanelPhone">{{ user.role === 'dentist' ? '*' : null}}ТЕЛЕФОН</label>
            </td>
            <td>
              <ClickableInput
                v-model="user.phone"
                id="userPanelPhone"
                placeholder="Добави"
                @enter="save()"
              />
            </td>
          </tr>
          <tr v-if="user.role === 'pacient' && shouldSee">
            <td>
              <span class="icon">I</span>
              <label for="userPanelDentists">ЛЕКАР/И</label>
            </td>
            <td>
              <p class="dentist-item" v-for="(d, i) in user.dentists" :key="d._id">
                <span>{{ d.name }}</span>
                <span
                  class="icon clear"
                  v-if="currentUser.role !== 'dentist' || d._id === currentUser._id "
                  @click="removeDentist(i)"
                >L</span>
              </p>
              <span
                class="icon new-dentist-toggle"
                @click="addingDentist = true"
                v-if="user.dentists.length < 10"
              >W</span>
            </td>
          </tr>
          <tr>
            <td>
              <p class="egn-rd-row">
                <span class="icon">K</span>
                <label for="userPanelEGN">{{ user.role === 'pacient' ? '*' : null}}ЕГН</label>
              </p>
            </td>
            <td>
              <ClickableInput
                v-model="user.egn"
                id="userPanelEgn"
                placeholder="Добави"
                @enter="save()"
              />
            </td>
          </tr>
          <tr v-if="user.role !== 'pacient'">
            <td>
              <span class="icon">k</span>
              <label for="userPanelAvatar">СНИМКА</label>
            </td>
            <td>
              <div class="file-input">
                <label for="userPanelAvatar" v-if="!avatar && !user.avatar">Избери изображение...</label>
                <label class="selected" v-else @click="resetAvatar">
                  <template
                    v-if="avatar"
                  >{{ avatar.name.length > 20 ? avatar.name.substr(0, 20) + '...' : avatar.name }}</template>
                  <template v-else>Ново изображение...</template>
                </label>
                <input
                  type="file"
                  id="userPanelAvatar"
                  placeholder="Избери изображение..."
                  @change="onAvatarSelected($event)"
                />
              </div>
            </td>
          </tr>
        </table>
        <div class="info" v-if="!id && currentUser.role === 'dentist' && user.role === 'pacient'">
          <p class="center">Потребителското име и паролата се генерират автоматично.</p>
          <p
            class="center"
          >Пациентът ще получи данните за вход при посещението на рентгеновата лаборатория.</p>
          <p class="center">По-късно ще може да ги смени сам, ако желае.</p>
        </div>
      </form>
      <div class="footer">
        <Button class="red button--mobile-circle" v-if="currentUser._id === id" @click="logout()">
          <span class="icon">F</span>
          <span>ИЗХОД</span>
        </Button>
        <Button class="blue button--mobile-circle" @click="save()">
          <span class="icon">j</span>
          <span>ЗАПИШИ</span>
        </Button>
        <Button class="grey button--mobile-circle" @click="close()">
          <span class="icon">L</span>
          <span>НАЗАД</span>
        </Button>
      </div>
      <transition name="fade">
        <AddDentist
          v-if="addingDentist"
          @close="addingDentist = false"
          @selected="onDentistSelected"
          :selected="user.dentists"
          :iniciator="currentUser"
        />
      </transition>
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import Panel from '@/components/Panel.vue';
import ClickableInput from '@/components/ClickableInput.vue';
import Role from '@/components/Role.vue';
import Button from '@/components/Button.vue';
import AddDentist from '@/components/AddDentist.vue';
export default {
  components: {
    Panel,
    AddDentist,
    ClickableInput,
    Role,
    Button
  },
  props: ['id', 'tabsSpace'],
  computed: {
    ...mapGetters(['currentUser']),
    shouldSee() {
      if (this.currentUser.role === 'dentist') {
        return this.id === this.currentUser._id;
      }

      return true;
    }
  },
  data() {
    return {
      addingDentist: false,
      avatar: null,
      user: {
        username: '',
        name: '',
        role: 'pacient',
        email: '',
        password: '',
        location: '',
        phone: '',
        egn: '',
        birthday: '',
        avatar: '',
        dentists: []
      }
    };
  },
  methods: {
    formatUsername() {
      if (this.user.username.match(/[^A-Za-z0-9._]/g)) {
        this.$snotify.warning(
          'За потребителско име използвайте само английски символи, числа, точка или долна черта.'
        );
      }
      this.user.username = this.user.username
        .replace(/[^A-Za-z0-9._]/g, '')
        .toLowerCase();
    },
    formatName() {
      if (this.user.name.length) {
        let output = this.user.name.split(' ');

        output.forEach((o, i) => {
          let x = o.slice(0, 1).toUpperCase();
          if (o.length > 1) {
            x += o.slice(1);
          }

          output[i] = x;
        });

        this.user.name = output.join(' ');
      }
    },
    generateRandomPassword() {
      this.user.password = Math.floor(100000 + Math.random() * 900000) + '';
    },
    nextRole() {
      if (this.currentUser.role === 'dentist') {
        return this.$snotify.error('Не може да променяте роли!');
      }
      if (this.id !== this.currentUser._id) {
        const roles = ['admin', 'employee', 'dentist', 'pacient'];
        if (this.currentUser.role !== 'admin') {
          roles.shift();
        }
        let i = roles.indexOf(this.user.role);
        this.user.role = i < roles.length - 1 ? roles[i + 1] : roles[0];

        if (this.user.role === 'pacient') {
          this.generateRandomPassword();
        } else {
          this.user.password = '';
        }
      } else {
        this.$store.dispatch(
          'error',
          'Не може да промените собствената си роля!'
        );
      }
    },
    resetAvatar() {
      this.avatar = null;
      this.user.avatar = '';
    },
    onAvatarSelected(e) {
      if (this.validateImage(e.target.files[0])) {
        this.avatar = e.target.files[0];
      }
    },
    validateImage(file) {
      if (file) {
        if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/)) {
          this.$store.dispatch(
            'error',
            'Можете да качвате само изображения във формат - .jpg, .png или .gif'
          );
          return false;
        }
        return true;
      }
    },
    onDentistSelected(d) {
      this.addingDentist = false;
      this.user.dentists.push(d);
    },
    removeDentist(i) {
      this.user.dentists.splice(i, 1);
    },
    getUser() {
      this.$apiService.get(`/users/${this.id}`).then(response => {
        this.user = response.data;
      });
    },
    save() {
      if (this.isValid()) {
        const fd = new FormData();

        fd.append('user', JSON.stringify(this.user));

        // for (const key in this.user) {
        //   fd.append(key, this.user[key]);
        // }

        if (this.avatar !== null) {
          fd.append('avatar', this.avatar);
        }

        if (this.id) {
          this.$apiService
            .put('/auth/update', fd)
            .then(() => {
              this.close(true);
              this.$snotify.success(
                `Успешно редактиран ${
                  this.user.role === 'pacient' ? 'пациент' : 'потребител'
                }!`
              );
            })
            .catch(err => {
              this.$snotify.error(err.response.data.message);
            });
        } else {
          this.$apiService
            .post('/auth/register', fd)
            .then(res => {
              this.close(
                true,
                this.user.role === 'pacient' ? res.data.user : null
              );
              this.$snotify.success(
                `Успешно регистриран ${
                  this.user.role === 'pacient' ? 'пациент' : 'потребител'
                }!`
              );
            })
            .catch(err => {
              this.$snotify.error(err.response.data.message);
            });
        }
      }
    },
    isValid() {
      if (!this.user.username && this.user.role !== 'pacient') {
        this.$snotify.error('Трябва да въведете потребителско име!');
        return false;
      }

      if (!this.user.password && !this.id) {
        this.$snotify.error('Трябва да въведете парола!');
        return false;
      }

      if (this.user.role === 'dentist') {
        if (!this.user.email) {
          this.$snotify.error('Трябва да въведете e-mail!');
          return false;
        }
        if (!this.user.location) {
          this.$snotify.error('Трябва да въведете адрес на практиката!');
          return false;
        }
        if (!this.user.phone) {
          this.$snotify.error('Трябва да въведете телефон!');
          return false;
        }
        if (!this.user.name) {
          this.$snotify.error('Трябва да въведете имена!');
          return false;
        }
      }

      if (this.user.role === 'pacient') {
        if (!this.user.name) {
          this.$snotify.error('Трябва да въведете имена!');
          return false;
        }
        if (!this.user.egn) {
          this.$snotify.error('Трябва да въведете ЕГН!');
          return false;
        }
      }
      return true;
    },
    close(update, pacient) {
      this.$emit('close-panel', { update: update, open: pacient });
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      });
    }
  },
  mounted() {
    if (this.id) {
      this.getUser();
    } else {
      if (this.currentUser.role === 'dentist') {
        this.user.dentists.push(this.currentUser);
      }
      this.generateRandomPassword();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@import '@/scss/transitions.scss';
.panel__content {
  .header {
    align-items: center;
    background-color: $white--bluish;
    color: $color;
    display: flex;
    height: $input-height * 2;
    max-height: $input-height * 2;
    min-height: $input-height * 2;
    padding: 10px;
    .icon {
      color: $blue;
      font-size: 2rem;
      margin-right: 10px;
    }
  }
  form {
    height: 100%;
    overflow: auto;
    padding: 10px;
    @include scrollbar(5px, #dadada);
    table {
      border-spacing: 0 10px;
      margin-bottom: -10px;
      margin-top: -10px;
      width: 100%;
      td {
        padding: 17px 20px;
        background-color: $white--greyish;
        &:first-child {
          width: 1%;
          white-space: nowrap;
        }
        .icon:not(.new-dentist-toggle):not(.clear) {
          color: rgba($color: #000, $alpha: 0.5);
          margin-left: 10px;
          margin-right: 20px;
          vertical-align: middle;
        }
        label {
          color: #000;
          font-size: 0.8rem;
          font-weight: 700;
        }
      }
    }

    p.error {
      margin-top: 10px;
    }

    .file-input {
      input {
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        width: 0.1px;
        z-index: -1;
      }
      label {
        border: 0;
        background-color: transparent;
        color: $blue;
        font-size: 1rem;
        font-weight: 400;
        outline: 0;
        width: 100%;
        @include hover();
        @include transition(opacity $transition $cubic);

        &.selected {
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }
  .footer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;
    .button:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.clear,
.new-dentist-toggle {
  align-items: center;
  background-color: $blue;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
  width: 20px;
  @include transition(all $transition $cubic);
  &:hover {
    background-color: $blue--button-hover;
  }
}

.dentist-item {
  align-items: center;
  border: 1px solid #3e3e3e;
  color: #3e3e3e;
  border-radius: 15px;
  display: inline-flex;
  min-height: 30px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 5px 5px 10px;
  span:not(.clear) {
    margin-right: 5px;
  }
  .clear {
    background-color: #3e3e3e;
    @include hover();
  }
}

.info {
  font-size: 0.8rem;
  padding: 10px;
}

.egn-rd-row {
  align-items: center;
  display: flex;
  .switch {
    margin: 0 10px;
  }
}

.underline {
  text-decoration: underline;
}

@media screen and (max-width: $xl) {
  .panel__content {
    .header {
      height: $xl-input-height * 2;
      max-height: $xl-input-height * 2;
      min-height: $xl-input-height * 2;
    }
  }
}
</style>