<template>
  <div :class="`role ${role}`" @click="$emit('click')">{{ roles[role] }}</div>
</template>

<script>
export default {
  props: ['role'],
  data() {
    return {
      roles: {
        admin: 'АДМИНИСТРАТОР',
        employee: 'МДЛ СЛУЖИТЕЛ',
        dentist: 'ДЕНТАЛЕН ЛЕКАР',
        pacient: 'ПАЦИЕНТ'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.role {
  align-items: center;
  border-radius: 2px;
  background-color: #d3d3d3;
  color: #fff;
  display: flex;
  font-size: 0.9rem;
  font-weight: 700;
  justify-content: center;
  height: 22px;
  width: 150px;
  @include transition(all $transition $cubic);
  @include noselect();

  &.role--hover {
    @include hover();
  }

  &.admin {
    background-color: #e32b54;
  }
  &.employee {
    background-color: #5245eb;
  }
  &.dentist {
    background-color: #e1b900;
  }
  &.pacient {
    background-color: #36d5be;
  }
}
</style>